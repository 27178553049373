import gql from 'graphql-tag';

const activitiesCreate = gql`
  mutation activitiesCreate($placeId: GUID, $spaceId: GUID!, $userId: GUID) {
    activitiesCreate(placeId: $placeId, spaceId: $spaceId, userId: $userId) {
      id
    }
  }
`;

const activitiesDelete = gql`
  mutation activitiesDelete($id: GUID!) {
    activitiesDelete(id: $id) {
      id
    }
  }
`;

const activitiesSearch = gql`
  mutation activitiesSearch($hitsPerPage: Int, $q: String!, $spaceId: GUID!) {
    activitiesSearch(hitsPerPage: $hitsPerPage, q: $q, spaceId: $spaceId) {
      hits {
        id
        deliveries {
          id
          placeId
          containers {
            id
            depth
            name
            isRefrigerated
            hasPallet
            height
            isStackable
            quantity
            temperature
            weight
            width
          }
        }
        place {
          name
        }
        placeId
        userId
        user {
          id
          name
        }
      }
    }
  }
`;

const activitiesUpdate = gql`
  mutation activitiesUpdate($data: JSONObject, $id: GUID!) {
    activitiesUpdate(data: $data, id: $id) {
      id
    }
  }
`;

export {
  activitiesCreate,
  activitiesDelete,
  activitiesSearch,
  activitiesUpdate,
};
