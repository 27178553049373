/* eslint-disable max-len */
import React, { FC } from 'react';

import IconProps from '../props';

const IconRefresh: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <g transform="matrix(0.87358,0,0,0.87358,-0.729036,-0.73424)">
      <path
        d="M15.65,4.35C14.15,2.845 12.11,1.998 9.985,1.998C5.596,1.998 1.985,5.61 1.985,9.998C1.985,14.387 5.596,17.998 9.985,17.998C13.237,17.998 16.18,16.015 17.4,13L15.18,13C14.116,14.879 12.118,16.044 9.959,16.044C6.667,16.044 3.959,13.335 3.959,10.044C3.959,6.752 6.667,4.044 9.959,4.044C11.539,4.044 13.057,4.668 14.18,5.78L11,9L18,9L18,2L15.65,4.35Z"
        fill={color || 'currentColor'}
      />
    </g>
  </svg>
);

export default IconRefresh;
