import keyBy from 'lodash/keyBy';
import React, { FC, ReactElement, SyntheticEvent } from 'react';

import DeliveryType from '../../types/Delivery';
import PlaceType from '../../types/Place';
import Item from '../new/Item';
import ItemSub from '../new/Item/Sub';

interface DeliveryItemProps {
  customInfos?: ReactElement;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  editPathname?: string;
  delivery: DeliveryType;
  places?: PlaceType[];
}

const DeliveryItem: FC<DeliveryItemProps> = ({
  customInfos,
  deleteOnClick,
  editPathname,
  delivery,
  places,
}) => {
  const { containers, placeId } = delivery;
  const placesLUT = places ? keyBy(places, 'id') : undefined;

  let place;
  if (placesLUT && placeId && placesLUT[delivery.placeId]) {
    place = placesLUT[delivery.placeId] as PlaceType;
  } else if (delivery.place) {
    place = delivery.place;
  }

  if (undefined === place) {
    return <div>L&apos;adresse n&apos;existe pas</div>;
  }

  const { formattedAddress, name } = place as PlaceType;

  return (
    <Item
      customInfos={customInfos}
      deleteOnClick={deleteOnClick}
      editPathname={editPathname}
      id={delivery.id}
      subTitle={formattedAddress}
      title={name}
    >
      {containers && containers.length > 0 && (
        <div className="flex flex-col space-y-2">
          {containers.map(container => (
            <ItemSub
              key={container.id}
              subTitle={`${container.width} x ${container.depth} x ${container.height} cm - ${container.weight} kg`}
              title={container.name}
            />
          ))}
        </div>
      )}
    </Item>
  );
};

export default DeliveryItem;
