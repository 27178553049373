import { Unsubscribe } from '@firebase/firestore';
import { Form, submit, useDispatch } from 'dataformjs';
import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import React, { FC, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ActivityDeliveries from '../../../../components/Activity/Deliveries';
import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import IconAdd from '../../../../components/icons/Add';
import IconDelete from '../../../../components/icons/Delete';
import IconRefresh from '../../../../components/icons/Refresh';
import IconSave from '../../../../components/icons/Save';
import AsideBody from '../../../../components/Space/Aside/Body';
import Collection from '../../../../models/Collection';
import activityData from '../../../../objects/activities/datas.json';
import {
  activitiesDelete as deleteMutation,
  activitiesUpdate as updateMutation,
} from '../../../../objects/activities/mutations';
import activityQuery from '../../../../objects/activities/queries/activity.graphql';
import ActivityType from '../../../../types/Activity';
import requiredAuth from '../../../../utils/requireAuth';

const ActivityPage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'activity';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteMutationFunc] = useMutation(deleteMutation);
  const [updateMutationFunc] = useMutation(updateMutation);
  const { data, loading, refetch } = useQuery(activityQuery, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    let unsub: Unsubscribe;
    if (id && refetch) {
      if (typeof window !== `undefined`) {
        unsub = Collection.onSnapshot('activity', id, snapshot => {
          if (!snapshot.exists()) {
            return Collection.initDoc('activity', id);
          }
          console.info('refetch activity', id);

          return refetch();
        });
      }
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [id, refetch]);

  const activity: ActivityType = data && (data.activity as ActivityType);

  if (!activity) {
    return <CircularProgress fullPage />;
  }

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  const handleActivityDeleteOnClick = async (): Promise<void> => {
    const ok = window.confirm(
      'Etes-vous sûr de vouloir supprimer cette activité ?',
    );

    if (ok) {
      const result = await deleteMutationFunc({
        variables: {
          id,
        },
      });

      if (!result || result.errors) {
        console.error(result.errors);
      } else {
        window.history.back();
      }
    }
  };

  const handleOnSubmit = async (values: any) => {
    const { isActive, place, user } = values;
    await toast.promise(
      updateMutationFunc({
        variables: {
          id,
          data: {
            isActive: Boolean(isActive),
            placeId: place?.id,
            userId: user?.id,
          },
        },
      }),
      {
        error: t('activities.update.submit.error'),
        pending: t('activities.update.submit.pending'),
        success: t('activities.update.submit.success'),
      },
    );
  };

  const deliveries = activity.deliveries.map(d => d.place);

  return (
    <GridSplit
      aside={{
        body: (
          <AsideBody
            optimizeWaypoints
            places={compact([activity.place, ...deliveries, activity.place])}
            showRoutes
          />
        ),
        className: 'sticky top-0 self-start',
        width: '50%',
      }}
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              iconRight={IconRefresh}
              onClick={() => refetch()}
              size="small"
              status="secondary"
            />
            <Button
              iconRight={IconAdd}
              size="small"
              status="secondary"
              to={`/admin/deliveries/create/?activity=${activity.id}`}
            >
              {t('activities.update.actions.addDelivery')}
            </Button>
            <Button
              iconLeft={IconSave}
              onClick={handleSaveOnClick}
              size="small"
              title={t('activity.actions.update')}
            />
            <Button
              iconLeft={IconDelete}
              onClick={handleActivityDeleteOnClick}
              size="small"
              status="danger"
              title={t('activity.actions.delete')}
            />
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: t(`activity.name`),
      }}
    >
      {loading && <CircularProgress />}
      <Form
        className="mx-6 mb-6"
        datas={activityData}
        hideSubmitButton
        initialValues={activity}
        name={formName}
        onSubmit={handleOnSubmit}
        params={{
          activityId: activity.id,
          spaceId: activity.spaceId,
        }}
        submitProps={{
          label: t(`activity.form.submit.label`),
          size: 'small',
        }}
      />
      <ActivityDeliveries
        activity={activity}
        className="flex flex-col space-y-6 mx-6"
      />
    </GridSplit>
  );
};

export default requiredAuth(ActivityPage, {
  admin: true,
  layout: true,
});
