import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ActivityType from '../../../types/Activity';
import DeliveryItem from '../../item/Delivery';

interface ActivityDeliveriesProps {
  activity: ActivityType;
  className?: string;
}

const ActivityDeliveries: FC<ActivityDeliveriesProps> = ({
  activity,
  className,
}) => {
  const { t } = useTranslation();

  if (!activity.deliveries || 0 === activity.deliveries.length) {
    return <div className={className}>{t('activity.deliveries.empty')}</div>;
  }

  return (
    <div className={className}>
      <h2 className="text-lg font-bold">{t('activity.deliveries.title')}</h2>
      {activity.deliveries.map(delivery => (
        <DeliveryItem
          key={delivery.id}
          delivery={delivery}
          editPathname="/admin/deliveries/update/"
        />
      ))}
    </div>
  );
};

export default ActivityDeliveries;
